import React, { Component } from "react"
import {Modal, Button, Form, Alert} from "react-bootstrap"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {navigate} from 'gatsby'
import {quizkyCrampeteAPI} from '../config'
class hireNowModel extends Component {
  
  constructor(props) {
    const { queryString, urlParams, utmParams } = props;
    super(props)
    
    this.state = {
      fields: {},
      errors: {},
      queryString: global.window?.location.search,
      urlParams : new URLSearchParams(queryString),
      utmParams: {utmSource: urlParams?.get("utm_source") || ""},
      source: utmParams?.utmSource ? utmParams?.utmSource : 'b2b',
      url: global.window?.location.href,
      success: "",
      submitState: false,
      phoneNumber: null
    }
  }

  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true

    //Name
    if (!fields["name"]) {
      formIsValid = false
      errors["name"] = "Please enter your name"
    }

    //company Name
    if (!fields["referenceCompanyName"]) {
      formIsValid = false
      errors["referenceCompanyName"] = "Please enter your company name"
    }

    //Mobile
    if (!fields["mobile"]) {
      formIsValid = false
      errors["mobile"] = "Please enter your mobile number"
    } 

    //Email
    if (!fields["email"]) {
      formIsValid = false
      errors["email"] = "Please enter your email"
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@")
      let lastDotPos = fields["email"].lastIndexOf(".")

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors["email"] = "Please enter valid email"
      }
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = ( field === 'mobile' ? e : e.target.value ) 
    this.setState({ fields })
  }

  handleSubmit = async event => {
    event.preventDefault()
    this.handleValidation()
    if (this.handleValidation()) {
      this.setState({ submitState: true })

      const data = {
        "studentName": this.state.fields["name"],
        "emailId": this.state.fields["email"],
        "mobileNumber": this.state.fields["mobile"],
        "referenceCompanyName": this.state.fields["referenceCompanyName"],
        "source": this.state.source,
        "url": this.state.url
    }

      const submitData = await fetch(`${quizkyCrampeteAPI}/common-lead/zoho`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )

      let resp = await submitData.json()
      if (resp.success) {
        navigate('/thank-you/')
      } else {
        this.setState({ success: false })
      }
      setTimeout(
        () => this.setState({ submitState: false, fields: {}, success: false }),
        10000
      )
      document.getElementById("get-start-form").reset()
    }
  }

  render() {
    
    const rspSuccess = (
      <Alert
        className={`${this.state.success === true ? "d-block" : "d-none"}`}
        variant="success"
        style={{ fontSize: "14px", textAlign: "center" }}
      >
        Thank you, your enquiry has been submitted !
      </Alert>
    )
    const rspFail = (
      <Alert
        className={`${this.state.success === false ? "d-block" : "d-none"}`}
        variant="danger"
        style={{ fontSize: "14px", textAlign: "center" }}
      >
        Something went wrong please try again later!
      </Alert>
    )
    const submitMsg = this.state.success ? rspSuccess : rspFail

    return (
      <Modal
        className="getStartedForm"
        centered
        show={this.props.showPop}
        onHide={this.props.hidePop}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center font-weight-bold mt-3 mb-3">
            Get in Touch
          </div>
          <Form
            id="get-start-form"
            onSubmit={this.handleSubmit.bind(this)}
            method="post"
          >
            {this.state.submitState ? submitMsg : ""}
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={this.handleChange.bind(this, "name")}
              />
              <Form.Text className="text-danger">
                {this.state.errors["name"]}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={this.handleChange.bind(this, "email")}
              />
              <Form.Text className="text-danger">
                {this.state.errors["email"]}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="mobile">
              <Form.Label>Mobile Number</Form.Label>
              <PhoneInput defaultCountry="IN" value={this.state.phoneNumber} onChange={this.handleChange.bind(this, "mobile")} />
              <Form.Text className="text-danger">
                {this.state.errors["mobile"]}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="referenceCompanyName"
                onChange={this.handleChange.bind(this, "referenceCompanyName")}
              />
              <Form.Text className="text-danger">
                {this.state.errors["referenceCompanyName"]}
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default hireNowModel

import React, { Component } from "react"
import Layout from "../../components/layout"
import Button from "react-bootstrap/Button"
import "../../styles/hire-from-us.css"
import Hirenowmodel from '../../components/hirenowmodel'  

export const seo = {
  title: "Hire From Us | Crampete",
  description:
    "Crampete is an online education technology platform. You can Hire Practically Trained Full Stack Developers, Cloud Computing Associates & Data Scientists at No Cost from us.",
  keywords: "Hire from us",
  author: "Crampete",
}
class HireFromUs extends Component {

  state = {
    show: false,
    setShow: false
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }
  render() {

    
  return (
    <Layout
    title={seo.title}
    description={seo.description}
    keywords={seo.keywords}
    author={seo.author}>
      <div className="" style={{ fontFamily: "Lato" }}>
        <div className="banner-bg">
          <div className="container  banner-div">
            <div className="row  align-items-center">
              <div className="col-lg-7 col-12">
                <h1 className="header-h1">
                  Hire Practically Trained Candidates at No Cost!
                </h1>
                <p className="text-dark">
                  Full Stack Developers | Cloud Computing Associates | Data
                  Scientists
                </p>
                <Button className="mt-3" onClick={this.handleShow}>Hire Now</Button>
              </div>
              <div className="col-lg-5 col-12 px-0 px-lg-3 mt-lg-0 mt-5 ">
                <img
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/hire-practically-trained.png"
                  alt="Hire from us"
                  width={467}
                  height={450}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <h2 className="header-h2">Why Hire From Crampete!</h2>
          <div className="row d-flex my-5 card-gap">
            <div className="col-lg-4  col-12 px-0 card-hire rounded " style={{maxWidth:"380px", height: "472px" }}>
              <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/relevant-projects.png" alt="Practically Trained Candidates" width={380} height={265}/>
              <h3 className="mt-3 header-h3 px-3">Practically Trained Candidates</h3>
              <p className="mt-3 px-3 pb-0 pb-lg-5">
                Our candidates have already gained practical skills and
                experience, which makes them more prepared to contribute to your
                workplace
              </p>
            </div>
            <div className=" col-lg-4 col-12 card-hire px-0 rounded" style={{maxWidth:"380px", height: "472px"}}>
              <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/industry-experts.png" alt="Mentored By The Industry Experts" width={380} height={265}/>
              <h3 className="mt-3 header-h3 px-3">Mentored By The Industry Experts</h3>
              <p className="mt-3 px-3 pb-0 pb-lg-5">
                Our Candidates are mentored by seasoned professionals from the
                industry.
              </p>
            </div>
            <div className="col-lg-4 col-12 card-hire px-0 rounded" style={{maxWidth:"380px", height: "472px"}}>
              <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/relevant-projects.png" alt="Industry Relevant Projects" width={380} height={265}/>
              <h3 className="mt-3 header-h3 px-3">Industry Relevant Projects</h3>
              <p className="mt-3 px-3 pb-0 pb-lg-5">
                Our Candidates have worked on multiple mini projects & on one
                capstone project, they know the real world’s expectations and
                deadlines.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="container bg-advance rounded p-lg-5">
            <div className=" row align-items-center py-5 py-lg-0">
              <div className="col-lg-5 col-12 px-lg-3">
                <img
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/advantages-in-hire.png"
                  alt="Advantages When You Hire From Crampete"
                  width={454}
                  height={332}
                />
              </div>
              <div className="col-lg-7 col-12 pl-lg-5 pl-0 ">
                <div className="mx-4 mx-lg-0">
                <h3 className="card-h3 mt-5 mt-lg-0">
                  Advantages When You Hire From Crampete
                </h3>
                <ul className="mt-3 list-style ">
                  <li>- Diverse Profiles</li>
                  <li> - No Cost to You! </li>
                  <li>- An Account manager working with you exclusively. </li>
                  <li>- 1000+ resumes are waiting for you</li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <div className="container  mb-5">
            <h3 className="card-h3 text-center">Our Students Are Trained On</h3>
            <div className="row mt-5 justify-content-between">
              <div className="col-lg-4 col-12">
                <h4 className="course-tle">Full Stack Web Development</h4>
                <div className="row justify-content-between mt-3 px-3">
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/js.png" alt="javascript" width={60} height={60} />
                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/mongo-db.png" alt="mongo db" width={60} height={60}
                  />
                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/react-js.png 
"  alt="react js" width={60} height={60}/>
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/html.png" alt="html" width={60} height={60}/>
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/css.png" alt="css" width={60} height={60}/>
                </div>
              </div>
              <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                <h4 className="course-tle">Cloud Computing</h4>
                <div className="row justify-content-between mt-3 px-3">
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/dynamo-db.png" alt="dynamo db" width={71} height={70}/>

                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/lambda.png
" alt="lambda"
width={71} height={70} />
                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/aws.png
" alt="aws"
width={71} height={70} />
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/google-cloud.png" alt="google cloud" width={71} height={70}/>
                 
                </div>
              </div>
              <div className="col-lg-3 col-12 mt-4 mt-lg-0">
                <h4 className="course-tle">Data Science</h4>
                <div className="row mt-3 px-3">
                  <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/excel.png
" alt="excel" width={75} height={75}/>

                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/jupyter.png
" alt="jupyter" width={75} height={75} className="px-3"
                  />
                  <img
                    src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/hire/trained-in/python.png
" alt="python" width={75} height={75}
                  />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Hirenowmodel showPop={this.state.show} hidePop={this.handleClose} />
      </div>
    </Layout>
  )
}
}

export default HireFromUs;
